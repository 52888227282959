import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { AlertService } from '../../shared/services/alert.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  private alertService: AlertService = inject(AlertService);

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err) => {        
        this.alertService.manageError(err, 'error-dialog-interceptor');
        return throwError(() => new HttpErrorResponse(err));
      })
    );
  }
}
